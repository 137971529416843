import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { computed } from 'vue';

export function useActivity(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(ACTIVITY_QUERY, variables);
  const events = computed(() => ({
    billings: result.value?.billings?.nodes ?? [],
    deliveries: result.value?.deliveries?.nodes ?? [],
    orders: result.value?.orders?.nodes ?? [],
  }));

  onError((err) => {
    console.error('useActivity', err);
    error();
  });

  return {
    events,
    refetch,
    loading,
  };
}

const ACTIVITY_QUERY = gql`
  query getActivity($businessId: ID!, $supplierId: ID, $fromDate: ISODate, $toDate: ISODate) {
    billings(businessId: $businessId, supplierId: $supplierId, fromDate: $fromDate, toDate: $toDate) {
      nodes {
        id
        date: dateNew
        supplierId
        type
        taxAmount
        netAmount
        totalAmount
        orderLinks {
          amount
          order {
            id
          }
        }
        deliveryRefs {
          delivery {
            id
          }
        }
        imbalances(resolved: "all") {
          orderedAmounts {
            id
            amount
          }
          billedAmounts {
            id
            amount
          }
          resolved
        }
        source {
          ref
        }
      }
    }

    deliveries(businessId: $businessId, supplierId: $supplierId, fromDate: $fromDate, toDate: $toDate) {
      nodes {
        id
        date
        supplierId
        products {
          productId
          quantity
        }
        diffs {
          customerValue
          supplierValue
          resolved
        }
        imbalances {
          productId
        }
        source {
          ref
        }
        eventReferences {
          reference
          documentId
        }
      }
    }

    orders(businessId: $businessId, supplierId: $supplierId, fromDate: $fromDate, toDate: $toDate) {
      nodes {
        id
        date
        supplierId
        netAmount
        products {
          productId
          id
          discount
          price
          quantity
        }
        itemsCount {
          ordered
          returnNotice
        }
        differences {
          customerItemId
          amount
          productId
          quantity {
            supplierValue
            customerValue
            solved
          }
          price {
            supplierValue
            customerValue
            solved
          }

          discount {
            supplierValue
            customerValue
            solved
          }
        }
        source {
          type
          ref
        }
        eventReferences {
          reference
          documentId
        }
      }
    }
  }
`;
