import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export const useCreateBillingFromDocument = () => {
  const { error } = useNotification();
  const { mutate: createBillingFromDocument, loading, onDone, onError } = useMutation(MUTATION);

  onError((err) => {
    console.error('useCreateBillingFromDocument', err);
    error();
  });

  return {
    createBillingFromDocument,
    loading,
    onDone,
  };
};

const MUTATION = gql`
  mutation createBillingFromDocument($documentId: ID!, $businessId: ID!) {
    billingCreateFromDocument(documentId: $documentId, businessId: $businessId) {
      id
      type
    }
  }
`;
