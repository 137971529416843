import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

import { useNotification } from '@/modules/core';
import { computed } from 'vue';

export function useUncertainBillings(variables) {
  const { error } = useNotification();

  const { result, onError } = useQuery(UNCERTAIN_BILLINGS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));

  const uncertainBillings = computed(() => result?.value?.uncertainBillings?.nodes ?? []);

  onError((err) => {
    console.error('billing  - useUncertainBillings', err);
    error();
  });

  return {
    uncertainBillings,
  };
}

const UNCERTAIN_BILLINGS_QUERY = gql`
  query uncertainBillings($businessId: ID!) {
    uncertainBillings(businessId: $businessId) {
      nodes {
        id
        date
        supplierId
        netAmount
        taxAmount
        totalAmount
        source {
          document: documentNew2 {
            id
            documentNumber
            type
          }
        }
        eventReferences {
          documentId
        }
      }
    }
  }
`;
