import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification } from '@/modules/core';

export function useUnbilledOrders(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(UNBILLED_ORDERS_QUERY, variables);

  const unbilledOrders = computed(() => result.value?.unbilledOrders?.nodes ?? []);

  onError((err) => {
    console.error('billing - useUnbilledOrders', err);
    error();
  });

  return {
    unbilledOrders,
    loading,
    refetch,
  };
}

const UNBILLED_ORDERS_QUERY = gql`
  query unbilledOrders($businessId: ID, $supplierId: ID) {
    unbilledOrders(businessId: $businessId, supplierId: $supplierId) {
      nodes {
        id
        orderId
        supplierId
        date
        netAmount
      }
    }
  }
`;
