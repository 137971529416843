<template>
  <div>
    <small class="d-flex justify-content-between align-items-center">
      <span class="d-flex align-items-center">
        <div
          class="rounded-circle"
          :class="`${typeColor} ${$direction === 'ltr' ? 'me-2' : 'ms-2'}`"
          :style="{ width: '6px', height: '6px' }"
        />
        <div>
          {{ $t(`billing.billingManagement.billingOverview.${type}`, { count }) }}
        </div>
      </span>
      <span> {{ formatCentsToCurrency(total) ?? '-' }} </span>
    </small>
    <hr v-if="divider" class="my-1" />
  </div>
</template>

<script>
import { useCurrency } from '@/modules/core/compositions/money-currency';

export const OVERVIEW_DATA_TYPE = {
  APPROVED: 'approved',
  NOT_APPROVED: 'notApproved',
  MISSING: 'missing',
};

export default {
  props: {
    type: { type: String, required: true, validation: (value) => Object.values(OVERVIEW_DATA_TYPE).includes(value) },
    count: { type: Number, required: true },
    total: { type: Number, default: null },
    divider: { type: Boolean, default: false },
  },
  setup() {
    const { formatCentsToCurrency } = useCurrency({ maximumFractionDigits: 0 });

    return { formatCentsToCurrency };
  },
  computed: {
    typeColor() {
      switch (this.type) {
        case OVERVIEW_DATA_TYPE.APPROVED:
          return 'bg-success';
        case OVERVIEW_DATA_TYPE.NOT_APPROVED:
          return 'bg-danger';
        case OVERVIEW_DATA_TYPE.MISSING:
          return 'bg-warning';
        default:
          return '';
      }
    },
  },
};
</script>
