<template>
  <div>
    <h3 class="mb-4">
      <span>
        {{ $t('billing.billingManagement.tab.unapproved') }}
      </span>
      <span>
        {{ formatMonth(month) }}
      </span>
    </h3>
    <Table
      v-loading="eventsLoading"
      :columns="columns"
      :data="tableData"
      hover
      show-index
      rounded
      border
      :expandable.sync="expandableRows"
      @row-click="$emit('open-activity', { id: tableData[$event].id, type: 'billing' })"
    >
      <template #[`cell-${TABLE_HEADER.DATE}`]="{ rowData: { [TABLE_HEADER.DATE]: date } }">
        <p>
          {{ formatDate(date) }}
        </p>
      </template>

      <template #[`cell-${TABLE_HEADER.DOCUMENT}`]="{ rowData: { [TABLE_HEADER.DOCUMENT]: document } }">
        <a v-if="document" class="text-primary" @click.stop="$emit('open-document', { documentId: document.id })">
          {{ $t(`document.exports.schema.type.shortName.${document.type}`) }} {{ document.documentNumber }}
        </a>
      </template>

      <template #expandable-content="{ rowData: { deliveries, orders, missing } }">
        <ActivityData
          :loading="documentsLoading"
          :orders="orders"
          :deliveries="deliveries"
          :missing="missing"
          :is-admin="isAdmin"
          @resolve-missing-event="$emit('resolve-missing-event', $event)"
          @open-document="$emit('open-document', $event)"
          @open-activity="$emit('open-activity', $event)"
        />
      </template>

      >
      <template #[`cell-${TABLE_HEADER.STATUS}`]="{ rowData: { [TABLE_HEADER.STATUS]: status } }">
        <Status :status="status" />
      </template>

      <template #[`cell-${TABLE_HEADER.DETAILS}`]="{ rowData: { [TABLE_HEADER.DETAILS]: details } }">
        <div
          v-for="(detail, index) in details"
          :key="`${index}-${detail.slice(0, 5)}`"
          :style="{ lineHeight: 1 }"
          class="my-1"
        >
          <TruncatedText>
            {{ detail }}
          </TruncatedText>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import { isNil } from 'ramda';

import { Table, TruncatedText } from '@/modules/core';
import { options } from '@/locale/dateConfig';

import { getBillingStatus } from '../status';
import ActivityData from './Activity';
import { useCurrency } from '@/modules/core/compositions/money-currency';

const TABLE_HEADER = {
  SUPPLIER_NAME: 'supplierName',
  DATE: 'date',
  DOCUMENT: 'document',
  TOTAL_NET_AMOUNT: 'netAmount',
  TAX_AMOUNT: 'taxAmount',
  TOTAL_AMOUNT: 'totalAmount',
  STATUS: 'status',
  DETAILS: 'details',
};

const matchMissingEvent = ({ documentNumber, context }, billingReferences) => {
  return billingReferences?.some((references) => {
    const amountKeys = ['netAmount', 'totalAmount', 'debitAmount', 'creditAmount'];

    if (!isNil(references.documentNumber) && !isNil(documentNumber) && references.documentNumber === documentNumber)
      return true;

    return (
      new Date(references.issueDate) === context.issueDate &&
      amountKeys.some(
        (amountKey) =>
          !isNil(references[amountKey]) && !isNil(context[amountKey]) && references[amountKey] === context[amountKey]
      )
    );
  });
};

export default {
  components: {
    Table,
    ActivityData,
    TruncatedText,
    Status: () => import('@/modules/reconciliation/reconciliationStatus/Status'),
  },
  props: {
    isAdmin: { type: Boolean, required: true },
    eventsLoading: { type: Boolean, default: false },
    documentsLoading: { type: Boolean, default: false },
    month: { type: String, required: true },
    events: { type: Object, required: true },
    documents: { type: Array, required: true },
    missingEvents: { type: Array, required: true },
    suppliers: { type: Array, required: true },
  },
  setup() {
    const { formatCentsToCurrency } = useCurrency();

    return { formatCentsToCurrency };
  },
  data() {
    return {
      TABLE_HEADER,
      expandableRows: {},
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('billing.billingManagement.commonTable.supplierName'),
          key: TABLE_HEADER.SUPPLIER_NAME,
          width: '130px',
        },
        {
          header: this.$t('billing.billingManagement.commonTable.date'),
          key: TABLE_HEADER.DATE,
          width: '100px',
        },
        {
          header: this.$t('billing.billingManagement.commonTable.documentNumber'),
          key: TABLE_HEADER.DOCUMENT,
          width: '165px',
        },
        {
          header: this.$t('billing.billingManagement.commonTable.netAmount'),
          key: TABLE_HEADER.TOTAL_NET_AMOUNT,
          width: '140px',
        },
        {
          header: this.$t('billing.billingManagement.commonTable.taxAmount'),
          key: TABLE_HEADER.TAX_AMOUNT,
          width: '120px',
        },
        {
          header: this.$t('billing.billingManagement.commonTable.totalAmount'),
          key: TABLE_HEADER.TOTAL_AMOUNT,
          width: '140px',
        },
        {
          header: this.$t('billing.billingManagement.commonTable.status'),
          key: TABLE_HEADER.STATUS,
          width: '100px',
        },
        {
          header: this.$t('billing.billingManagement.commonTable.details'),
          key: TABLE_HEADER.DETAILS,
          width: '135px',
        },
      ];
    },
    tableData() {
      if (this.eventsLoading) return [];
      const billingsWithRelatedData = this.events.billings
        .filter((billing) => billing.imbalances.length)
        .map((billing) => {
          const supplier = this.suppliers.find((supplier) => supplier.id === billing.supplierId);
          const document = this.documents.find((document) => document.id === billing.source.ref);

          const orders = billing.orderLinks
            .map((orderLink) => this.events.orders.find((order) => order.id === orderLink.order?.id))
            .filter((order) => !!order);
          const deliveries = billing.deliveryRefs
            .map((deliveryRef) => this.events.deliveries.find((delivery) => delivery.id === deliveryRef.delivery?.id))
            .filter((delivery) => !!delivery);
          const missing = this.missingEvents
            .filter((missingEvent) => billing.supplierId === missingEvent.supplierId)
            .filter((missingEvent) => matchMissingEvent(missingEvent, document?.references));

          return {
            id: billing.id,
            [TABLE_HEADER.SUPPLIER_NAME]: supplier?.name ?? '-',
            [TABLE_HEADER.DATE]: billing.date,
            [TABLE_HEADER.DOCUMENT]: document,
            [TABLE_HEADER.TOTAL_NET_AMOUNT]: this.formatMoney(billing.netAmount),
            [TABLE_HEADER.TAX_AMOUNT]: this.formatMoney(billing.taxAmount),
            [TABLE_HEADER.TOTAL_AMOUNT]: this.formatMoney(billing.totalAmount),
            [TABLE_HEADER.DETAILS]: this.getDetails(billing, missing),
            [TABLE_HEADER.STATUS]: getBillingStatus(billing),
            orders: orders.map(this.setDocumentsFromEventReferences),
            deliveries: deliveries.map(this.setDocumentsFromEventReferences),
            missing,
            expandable: orders.length && deliveries.length,
            expandableCustomClass: 'p-0',
          };
        });
      return billingsWithRelatedData.sort((a, b) => a[TABLE_HEADER.DATE] - b[TABLE_HEADER.DATE]);
    },
  },
  methods: {
    formatMonth(month) {
      return new Date(month).toLocaleString(this.$i18n.locale, { month: 'long', year: 'numeric' });
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleDateString(this.$i18n.locale, options.short) : '-';
    },
    formatMoney(value) {
      return this.formatCentsToCurrency(value) ?? '-';
    },
    getDetails(billing, missing) {
      const texts = [];

      if (billing.imbalances.length)
        if (billing.totalAmount >= 0)
          texts.push(this.$tc('billing.billingManagement.details.imbalance', billing.orderLinks.length));
        else texts.push(this.$t('billing.billingManagement.details.notMatched'));

      if (missing.length)
        texts.push(
          this.$tc('billing.billingManagement.details.missing', missing.length, {
            count: missing.length,
          })
        );

      return texts.length ? texts : null;
    },
    setDocumentsFromEventReferences(event) {
      const eventDocRefs = event.eventReferences.map(({ documentId }) => documentId);
      const documents = this.documents.filter(({ id }) => eventDocRefs.includes(id));
      if (documents.length)
        return {
          ...event,
          documents: documents.map((document) => ({
            id: document.id,
            type: document.type,
            documentNumber: document.documentNumber,
          })),
        };

      return event;
    },
  },
};
</script>
