import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

import { useNotification } from '@/modules/core';

export function useProductsNew(variables) {
  const { error } = useNotification();

  const { result, loading, onError } = useQuery(PRODUCTS_QUERY, variables, () => ({
    enabled: !!variables.value?.ids?.length,
  }));
  const productConnection = computed(() => (result.value ? result.value.productsNew : { nodes: [], totalCount: 0 }));
  const totalCount = computed(() => productConnection.value.totalCount);

  const products = computed(() => productConnection.value.nodes);

  onError((err) => {
    console.error('useProducts', err);
    error();
  });

  return {
    products,
    loading,
    totalCount,
  };
}

const PRODUCTS_QUERY = gql`
  query productsNew($ids: [ID!]) {
    productsNew(ids: $ids) {
      totalCount
      nodes {
        id
        name
        sku
      }
    }
  }
`;
