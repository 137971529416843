import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { onUnmounted } from 'vue';

const onDoneSubscribedCallbacks = [];
const onErrorSubscribedCallbacks = [];

export const useBillingPatch = () => {
  const { mutate: patchBilling, loading, onDone, onError } = useMutation(PATCH_BILLING_ORDER_LINKS);
  onDone((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  onError((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  const onDoneCallbacks = [];
  const onErrorCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) => onDoneSubscribedCallbacks.splice(onDoneSubscribedCallbacks.indexOf(cb), 1));
    onErrorCallbacks.forEach((cb) => onErrorSubscribedCallbacks.splice(onErrorSubscribedCallbacks.indexOf(cb), 1));
  });

  return {
    patchBilling,
    loading,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDoneSubscribedCallbacks.push(callback);
    },
    onError: (callback) => {
      onErrorCallbacks.push(callback);
      onErrorSubscribedCallbacks.push(callback);
    },
  };
};

export const PATCH_BILLING_ORDER_LINKS = gql`
  mutation updateOrderLinks($id: ID!, $data: BillingPatchInput) {
    billingPatch(id: $id, data: $data) {
      id
      businessId
      netAmount
      source {
        document: documentNew2 {
          id
          documentNumber
          type
        }
      }
      orderLinks {
        externalRef
        amount
        order {
          id
        }
      }
      imbalances {
        billedAmounts {
          id
          amount
        }
        orderedAmounts {
          id
          amount
        }
      }
    }
  }
`;
