import { gql } from '@apollo/client/core';
import { computed, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { DateTime } from 'luxon';

export const useBillingsWithNavigation = (businessId, supplierId, defaultMonth) => {
  const date = ref(DateTime.fromFormat(defaultMonth, 'yyyy-MM'));
  const from = computed(() => date.value.startOf('month'));
  const to = computed(() => date.value.endOf('month'));

  const { result, loading } = useQuery(
    BILLINGS_QUERY,
    () => ({
      businessId: businessId.value,
      supplierId: supplierId.value,
      from: from.value.toISODate(),
      to: to.value.toISODate(),
    }),
    () => ({ enabled: !!businessId.value })
  );
  const billings = computed(() => result.value?.billings?.nodes ?? []);

  return {
    billings,
    range: computed(() => ({
      start: from.value.toJSDate(),
      end: to.value.toJSDate(),
    })),
    loading,
    goForward: () => {
      date.value = date.value.plus({ months: 1 });
    },
    goBackward: () => {
      date.value = date.value.minus({ months: 1 });
    },
  };
};

export const BILLINGS_QUERY = gql`
  query getBillings($businessId: ID!, $supplierId: ID, $from: ISODate, $to: ISODate) {
    billings(businessId: $businessId, supplierId: $supplierId, fromDate: $from, toDate: $to) {
      nodes {
        id
        date
        netAmount
        totalAmount
        eventReferences {
          documentId
          reference
          regulatory
        }
        source {
          document: documentNew2 {
            id
            documentNumber
            type
          }
        }

        orderLinks {
          externalRef
          amount
          order {
            id
          }
        }
      }
    }
  }
`;
